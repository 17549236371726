import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  template: `<p>Cette page n'existe pas.</p>`
})
export class NotFoundComponent {

}
