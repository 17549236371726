<mat-card class="card" appearance="outlined" [style.background-color]="bgColor()">
  <mat-card-header>
    <mat-card-title>Offre {{offer.title}}</mat-card-title>
    <mat-card-subtitle>
      {{offer.price}} €
      @if (offer.ntickets > 1) {
      les {{offer.ntickets}} tickets
      } @else {
      le ticket
      }
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      {{offer.description}}
    </p>
  </mat-card-content>
  <mat-card-footer class="card-footer">
    <div class="button-row">
      <button mat-flat-button (click)="add(offer)">Ajouter au panier</button>
    </div>
  </mat-card-footer>

</mat-card>