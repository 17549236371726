<form class="container" (submit)="create()">
  <mat-form-field class="container-row">
    <mat-label>Votre Nom</mat-label>
    <input matInput [formControl]="lastname" (blur)="updateErrorMessageLastname()" required />
    @if (lastname.invalid) {
    <mat-error>{{errorMessageLastname()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre Prénom</mat-label>
    <input matInput [formControl]="firstname" (blur)="updateErrorMessageFirstname()" required />
    @if (firstname.invalid) {
    <mat-error>{{errorMessageFirstname()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre Mail</mat-label>
    <input matInput [formControl]="mail" (blur)="updateErrorMessageMail()" required type="email" />
    @if (mail.invalid) {
    <mat-error>{{errorMessageMail()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre Téléphone Fixe</mat-label>
    <input matInput [formControl]="phone_landline" (blur)="updateErrorMessagePhonelandline()" />
    @if (phone_landline.invalid) {
    <mat-error>{{errorMessagePhonelandline()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre Portable</mat-label>
    <input matInput [formControl]="phone_cell" (blur)="updateErrorMessagePhonecell()" />
    @if (phone_cell.invalid) {
    <mat-error>{{errorMessagePhonecell()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre Adresse</mat-label>
    <input matInput [formControl]="address" (blur)="updateErrorMessageAddress()" required />
    @if (address.invalid) {
    <mat-error>{{errorMessageAddress()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre Code Postal</mat-label>
    <input matInput [formControl]="code_postal" (blur)="updateErrorMessageCodepostal()" required />
    @if (code_postal.invalid) {
    <mat-error>{{errorMessageCodepostal()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre Ville</mat-label>
    <input matInput [formControl]="city" (blur)="updateErrorMessageCity()" required />
    @if (city.invalid) {
    <mat-error>{{errorMEssageCity()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre identifant</mat-label>
    <input matInput [formControl]="username" (blur)="updateErrorMessageUsername()" required />
    @if (username.invalid) {
    <mat-error>{{errorMessageUsername()}}</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="container-row">
    <mat-label>Votre mot de passe</mat-label>
    <input matInput [formControl]="password" (blur)="updateErrorMessagePassword()" required type="password"
      autocomplete />
    @if (password.invalid) {
    <mat-error>{{errorMessagePassword()}}</mat-error>
    }
  </mat-form-field>

  @if (invalidIdentifiant) {
  <div class="credentials-invalid">
    <mat-error>Identifiant non disponible.</mat-error>
  </div>
  }

  @if (invalidPwd) {
  <div class="credentials-invalid">
    <mat-error>Mot de passe refusé.</mat-error>
  </div>
  }

  @if (errorCreate) {
  <div class="credentials-invalid">
    <mat-error>Vérifiez les données saisies.<br>Contactez le support client en cas de
      difficultés.</mat-error>
  </div>
  }

  <button type="submit" mat-flat-button class="button-submit">Envoyer</button>
</form>