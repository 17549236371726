import { inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IAccount } from '../../interfaces/account.interface';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountCreateService {
  private readonly apiUrl = environment.apiURL
  private readonly http = inject(HttpClient);

  create(account: IAccount): Observable<any> {
    return this.http.post<any>(this.apiUrl + "account/", account);
  }

  tests_anwswer = signal<any>("");
  tests(test_keys: any) {
    return this.http.post<any>(this.apiUrl + "account/tests/", test_keys).pipe(
      tap((response) => { this.tests_anwswer.set(response) }),
    );
  }
}
