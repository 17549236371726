import { Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { merge } from 'rxjs';
import { phoneRegex } from '../../core/regex/phone.regex';
import { AccountCreateService } from '../../core/services/account-create/account-create.service';
import { IAccount } from '../../core/interfaces/account.interface';
import { Location } from '@angular/common';


@Component({
  selector: 'app-account-create',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatButton],
  templateUrl: './account-create.component.html',
  styleUrl: './account-create.component.css'
})
export class AccountCreateComponent {
  accountCreateService = inject(AccountCreateService)
  readonly lastname = new FormControl('', [Validators.required]);
  readonly firstname = new FormControl('', [Validators.required]);
  readonly mail = new FormControl('', [Validators.required, Validators.email]);
  readonly phone_landline = new FormControl('', [Validators.pattern(phoneRegex)]);
  readonly phone_cell = new FormControl('', [Validators.pattern(phoneRegex)]);
  readonly address = new FormControl('', [Validators.required]);
  readonly code_postal = new FormControl('', [Validators.required]);
  readonly city = new FormControl('', [Validators.required]);
  readonly username = new FormControl('', [Validators.required]);
  readonly password = new FormControl('', [Validators.required]);

  errorMessageLastname = signal('');
  errorMessageFirstname = signal('');
  errorMessageMail = signal('');
  errorMessagePhonelandline = signal('');
  errorMessagePhonecell = signal('');
  errorMessageAddress = signal('');
  errorMessageCodepostal = signal('');
  errorMEssageCity = signal('');
  errorMessageUsername = signal('');
  errorMessagePassword = signal('');

  invalidIdentifiant = false;
  invalidPwd = false;
  errorCreate = false;

  constructor(private location: Location) {
    merge(this.lastname.statusChanges, this.lastname.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessageLastname());

    merge(this.firstname.statusChanges, this.firstname.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessageFirstname());

    merge(this.mail.statusChanges, this.mail.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessageMail());

    merge(this.phone_landline.statusChanges, this.phone_landline.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessagePhonelandline());

    merge(this.phone_cell.statusChanges, this.phone_cell.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessagePhonecell());

    merge(this.address.statusChanges, this.address.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessageAddress());

    merge(this.code_postal.statusChanges, this.code_postal.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessageCodepostal());

    merge(this.city.statusChanges, this.city.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessageCity());


    merge(this.username.statusChanges, this.username.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessageUsername());

    merge(this.password.statusChanges, this.password.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessagePassword());
  }

  updateErrorMessageLastname() {
    if (this.lastname.hasError('required')) {
      this.errorMessageLastname.set('Vous devez entrer votre nom.');
    } else if (this.lastname.hasError('lastname')) {
      this.errorMessageLastname.set("Ce n'est pas un identifiant valide.");
    } else {
      this.errorMessageLastname.set('');
    }
  }

  updateErrorMessageFirstname() {
    if (this.firstname.hasError('required')) {
      this.errorMessageFirstname.set('Vous devez entrer votre prénom.');
    } else if (this.firstname.hasError('firstname')) {
      this.errorMessageFirstname.set("Ce n'est pas un identifiant valide.");
    } else {
      this.errorMessageFirstname.set('');
    }
  }

  updateErrorMessageMail() {
    if (this.mail.hasError('required')) {
      this.errorMessageMail.set('Vous devez entrer votre mail.');
    } else if (this.mail.hasError('email')) {
      this.errorMessageMail.set("Ce n'est pas un mail valide.");
    } else {
      this.errorMessageMail.set('');
    }
  }

  updateErrorMessagePhonelandline() {
    console.log(this.phone_landline.errors)
    if (this.phone_landline.hasError('pattern')) {
      this.errorMessagePhonelandline.set("Ce n'est pas un numéro valide.");
    } else if (this.phone_landline.hasError('phone_landline')) {
      this.errorMessagePhonelandline.set("Ce n'est pas un numéro valide.");
    } else {
      this.errorMessagePhonelandline.set('');
    }
  }

  updateErrorMessagePhonecell() {
    if (this.phone_cell.hasError('pattern')) {
      this.errorMessagePhonecell.set("Ce n'est pas un numéro valide.");
    } else if (this.phone_cell.hasError('phone_cell')) {
      this.errorMessagePhonecell.set("Ce n'est pas un numéro valide.");
    } else {
      this.errorMessagePhonecell.set('');
    }
  }

  updateErrorMessageAddress() {
    if (this.address.hasError('required')) {
      this.errorMessageAddress.set('Vous devez entrer votre adresse.');
    } else if (this.address.hasError('address')) {
      this.errorMessageAddress.set("Ce n'est pas une adresse valide.");
    } else {
      this.errorMessageAddress.set('');
    }
  }

  updateErrorMessageCodepostal() {
    if (this.code_postal.hasError('required')) {
      this.errorMessageCodepostal.set('Vous devez entrer votre code postal.');
    } else if (this.code_postal.hasError('code_postal')) {
      this.errorMessageCodepostal.set("Ce n'est pas un code postal valide.");
    } else {
      this.errorMessageUsername.set('');
    }
  }

  updateErrorMessageCity() {
    if (this.city.hasError('required')) {
      this.errorMEssageCity.set('Vous devez entrer votre ville.');
    } else if (this.city.hasError('city')) {
      this.errorMEssageCity.set("Ce n'est pas une ville valide.");
    } else {
      this.errorMEssageCity.set('');
    }
  }


  updateErrorMessageUsername() {
    this.accountCreateService.tests({ username: this.username.value }).subscribe();
    const tests_anwswer = this.accountCreateService.tests_anwswer;
    // console.log(tests_anwswer())
    if (tests_anwswer()['username'] === "fail") {
      this.invalidIdentifiant = true;
      // this.errorMessageUsername.set("Cet identifiant n'est pas disponible.");
    } else {
      this.invalidIdentifiant = false;
    }
    if (this.username.hasError('required')) {
      this.errorMessageUsername.set('Vous devez entrer un identifiant.');
    } else if (this.username.hasError('username')) {
      this.errorMessageUsername.set("Ce n'est pas un identifiant valide.");
    } else {
      this.errorMessageUsername.set('');
    }
  }

  updateErrorMessagePassword() {
    this.accountCreateService.tests({ password: this.password.value }).subscribe();
    const tests_anwswer = this.accountCreateService.tests_anwswer;
    // console.log(tests_anwswer())
    if (tests_anwswer()['password'] === "fail") {
      this.invalidPwd = true;
      // this.errorMessageUsername.set("Cet identifiant n'est pas disponible.");
    } else {
      this.invalidPwd = false;
    }
    if (this.password.hasError('required')) {
      this.errorMessagePassword.set('Vous devez entrer un mot de passe.');
    } else if (this.password.hasError('minlength')) {
      this.errorMessagePassword.set(
        'Vous devez utiliser'
        + this.password.errors!['minlength']['requiredLength']
        + ' caractères.'
      );
    } else {
      this.errorMessagePassword.set('');
    }
  }

  create() {
    const account: IAccount = {
      username: this.username.value as string,
      password: this.password.value as string
    }

    if (this.username.value) {
      account.username = this.username.value;
    }
    if (this.password.value) {
      account.password = this.password.value;
    }
    if (this.lastname.value) {
      account.last_name = this.lastname.value;
    }
    if (this.firstname.value) {
      account.first_name = this.firstname.value;
    }
    if (this.mail.value) {
      account.mail = this.mail.value;
    }
    if (this.phone_landline.value) {
      account.phone_landline = this.phone_landline.value;
    }
    if (this.phone_cell.value) {
      account.phone_cell = this.phone_cell.value;
    }
    if (this.address.value) {
      account.adress = this.address.value;
    }
    if (this.code_postal.value) {
      account.code_postal = this.code_postal.value;
    }
    if (this.city.value) {
      account.city = this.city.value;
    }

    this.accountCreateService.create(account).subscribe({
      // retour à la page précédente si le login réussi
      next: result => { this.location.back(); },
      // sinon informe de l'échec
      error: error => { this.errorCreate = true; }
    });
  }
}
