<mat-sidenav-container autosize>

  <mat-sidenav #burger mode="over" closed>
    <app-burger (closeBurgerButtonClick)=burger.close() />
  </mat-sidenav>

  <mat-sidenav-content class="container">
    <header>
      <app-header (openBurgerButtonClick)=burger.open() />
    </header>

    <main class="pages">
      <router-outlet>
      </router-outlet>
    </main>

    <footer>
      <app-footer />
    </footer>
  </mat-sidenav-content>

</mat-sidenav-container>